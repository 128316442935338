export const FEED_REQUESTED = 'FEED_REQUESTED';
export const FEED_SUCCESS = 'FEED_SUCCESS';
export const FEED_FAILED = 'FEED_FAILED';
export const POST_DETAIL_REQUESTED = 'POST_DETAIL_REQUESTED';
export const POST_DETAIL_SUCCESS = 'POST_DETAIL_SUCCESS';
export const POST_DETAIL_FAILED = 'POST_DETAIL_FAILED';
export const UPDATE_POST_DETAIL_REQUESTED = 'UPDATE_POST_DETAIL_REQUESTED';
export const UPDATE_POST_DETAIL_SUCCESS = 'UPDATE_POST_DETAIL_SUCCESS';
export const UPDATE_POST_DETAIL_FAILED = 'UPDATE_POST_DETAIL_FAILED';
export const CREATE_VIDEO_REQUESTED = 'CREATE_VIDEO_REQUESTED';
export const CREATE_VIDEO_SUCCESS = 'CREATE_VIDEO_SUCCESS';
export const CREATE_VIDEO_FAILED = 'CREATE_VIDEO_FAILED';
export const OPEN_CREATE_VIDEO_LAYER = 'OPEN_CREATE_VIDEO_LAYER';
export const CLOSE_CREATE_VIDEO_LAYER = 'CLOSE_CREATE_VIDEO_LAYER';
export const CREATE_POST_LIKE_REQUESTED = 'CREATE_POST_LIKE_REQUESTED';
export const CREATE_POST_LIKE_SUCCESS = 'CREATE_POST_LIKE_SUCCESS';
export const CREATE_POST_LIKE_FAILED = 'CREATE_POST_LIKE_FAILED';
export const REMOVE_POST_LIKE_REQUESTED = 'REMOVE_POST_LIKE_REQUESTED';
export const REMOVE_POST_LIKE_SUCCESS = 'REMOVE_POST_LIKE_SUCCESS';
export const REMOVE_POST_LIKE_FAILED = 'REMOVE_POST_LIKE_FAILED';
export const SEARCH_POST_REQUESTED = 'SEARCH_POST_REQUESTED';
export const SEARCH_POST_SUCCESS = 'SEARCH_POST_SUCCESS';
export const SEARCH_POST_FAILED = 'SEARCH_POST_FAILED';
export const CREATE_POST_COMMENT_REQUESTED = 'CREATE_POST_COMMENT_REQUESTED';
export const CREATE_POST_COMMENT_SUCCESS = 'CREATE_POST_COMMENT_SUCCESS';
export const CREATE_POST_COMMENT_FAILED = 'CREATE_POST_COMMENT_FAILED';
export const UPDATE_POST_COMMENT_REQUESTED = 'UPDATE_POST_COMMENT_REQUESTED';
export const UPDATE_POST_COMMENT_SUCCESS = 'UPDATE_POST_COMMENT_SUCCESS';
export const UPDATE_POST_COMMENT_FAILED = 'UPDATE_POST_COMMENT_FAILED';
export const REMOVE_POST_COMMENT_REQUESTED = 'REMOVE_POST_COMMENT_REQUESTED';
export const REMOVE_POST_COMMENT_SUCCESS = 'REMOVE_POST_COMMENT_SUCCESS';
export const REMOVE_POST_COMMENT_FAILED = 'REMOVE_POST_COMMENT_FAILED';
export const LIST_POST_COMMENTS_REQUESTED = 'LIST_POST_COMMENTS_REQUESTED';
export const LIST_POST_COMMENTS_SUCCESS = 'LIST_POST_COMMENTS_SUCCESS';
export const LIST_POST_COMMENTS_FAILED = 'LIST_POST_COMMENTS_FAILED';
export const LIST_POST_COMMENT_REPLIES_REQUESTED =
  'LIST_POST_COMMENT_REPLIES_REQUESTED';
export const LIST_POST_COMMENT_REPLIES_SUCCESS =
  'LIST_POST_COMMENT_REPLIES_SUCCESS';
export const LIST_POST_COMMENT_REPLIES_FAILED =
  'LIST_POST_COMMENT_REPLIES_FAILED';
export const CREATE_POST_COMMENT_LIKE_REQUESTED =
  'CREATE_POST_COMMENT_LIKE_REQUESTED';
export const CREATE_POST_COMMENT_LIKE_SUCCESS =
  'CREATE_POST_COMMENT_LIKE_SUCCESS';
export const CREATE_POST_COMMENT_LIKE_FAILED =
  'CREATE_POST_COMMENT_LIKE_FAILED';
export const REMOVE_POST_COMMENT_LIKE_REQUESTED =
  'REMOVE_POST_COMMENT_LIKE_REQUESTED';
export const REMOVE_POST_COMMENT_LIKE_SUCCESS =
  'REMOVE_POST_COMMENT_LIKE_SUCCESS';
export const REMOVE_POST_COMMENT_LIKE_FAILED =
  'REMOVE_POST_COMMENT_LIKE_FAILED';

export const REMOVE_POST_REQUESTED = 'REMOVE_POST_REQUESTED';
export const REMOVE_POST_SUCCESS = 'REMOVE_POST_SUCCESS';
export const REMOVE_POST_FAILED = 'REMOVE_POST_FAILED';
export const GET_FEATURED_POST_REQUESTED = 'GET_FEATURED_POST_REQUESTED';
export const GET_FEATURED_POST_SUCCESS = 'GET_FEATURED_POST_SUCCESS';
export const GET_FEATURED_POST_FAILED = 'GET_FEATURED_POST_FAILED';

export const ADD_POST_SUCCESS = 'ADD_POST_SUCCESS';
