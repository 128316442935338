export const baseMeetingConfig = {
  disableAudioLevels: false,
  audioLevelsInterval: 200,
  enableNoAudioDetection: true,
  enableNoisyMicDetection: true,
  startAudioOnly: false,
  startAudioMuted: 10,
  startWithAudioMuted: false,
  startVideoMuted: 10,
  startWithVideoMuted: false,
  startScreenSharing: false,
  // fileRecordingsEnabled: false,
  // liveStreamingEnabled: false,
  disableResponsiveTiles: false,
  hideLobbyButton: false,
  requireDisplayName: true,
  defaultLanguage: 'en',
  disableProfile: false,
  transcribingEnabled: false,
  autoCaptionOnRecord: false,
  disableShortcuts: false,
  disableTileView: false,
  toolbarButtons: [
    { setting: 'microphone' },
    { setting: 'camera' },
    { setting: 'closedcaptions' },
    { setting: 'desktop' },
    { setting: 'embedmeeting' },
    { setting: 'fullscreen' },
    { setting: 'fodeviceselection' },
    { setting: 'hangup' },
    { setting: 'profile' },
    { setting: 'chat' },
    { setting: 'recording' },
    { setting: 'livestreaming' },
    { setting: 'etherpad' },
    { setting: 'sharedvideo' },
    { setting: 'shareaudio' },
    { setting: 'settings' },
    { setting: 'raisehand' },
    { setting: 'videoquality' },
    { setting: 'filmstrip' },
    { setting: 'feedback' },
    { setting: 'stats' },
    { setting: 'shortcuts' },
    { setting: 'tileview' },
    { setting: 'select-background' },
    { setting: 'download' },
    { setting: 'help' },
    { setting: 'mute-everyone' },
    { setting: 'mute-video-everyone' },
    { setting: 'security' },
  ],
  advancedButtons: [
    { setting: 'disableAudioLevels' },
    { setting: 'enableNoAudioDetection' },
    { setting: 'enableNoisyMicDetection' },
    { setting: 'startAudioOnly' },
    { setting: 'startWithAudioMuted' },
    { setting: 'startWithVideoMuted' },
    { setting: 'startScreenSharing' },
    { setting: 'disableResponsiveTiles' },
    { setting: 'hideLobbyButton' },
    { setting: 'requireDisplayName' },
    { setting: 'disableProfile' },
    { setting: 'transcribingEnabled' },
    { setting: 'autoCaptionOnRecord' },
    { setting: 'disableShortcuts' },
    { setting: 'disableRecordAudioNotification' },
    { setting: 'disableRemoteMute' },
    { setting: 'hideConferenceSubject' },
    { setting: 'hideConferenceTimer' },
    { setting: 'hideParticipantsStats' },
  ],
  disableRecordAudioNotification: false,
  disableJoinLeaveSounds: false,
  disableRemoteMute: true,
  hideConferenceSubject: false,
  hideConferenceTimer: true,
  hideParticipantsStats: true,
};
