export const GET_USER_CHANNELS_REQUESTED = 'GET_USER_CHANNELS_REQUESTED';
export const GET_USER_CHANNELS_SUCCESS = 'GET_USER_CHANNELS_SUCCESS';
export const GET_USER_CHANNELS_FAILED = 'GET_USER_CHANNELS_FAILED';
export const JOIN_CHANNEL_REQUESTED = 'JOIN_CHANNEL_REQUESTED';
export const JOIN_CHANNEL_SUCCESS = 'JOIN_CHANNEL_SUCCESS';
export const JOIN_CHANNEL_FAILED = 'JOIN_CHANNEL_FAILED';
export const OPEN_CREATE_CHANNEL_LAYER = 'OPEN_CREATE_CHANNEL_LAYER';
export const CLOSE_CREATE_CHANNEL_LAYER = 'CLOSE_CREATE_CHANNEL_LAYER';
export const CREATE_CHANNEL_REQUESTED = 'CREATE_CHANNEL_REQUESTED';
export const CREATE_CHANNEL_SUCCESS = 'CREATE_CHANNEL_SUCCESS';
export const CREATE_CHANNEL_FAILED = 'CREATE_CHANNEL_FAILED';
export const SET_SELECTED_CHANNEL = 'SET_SELECTED_CHANNEL';
export const UNSET_SELECTED_CHANNEL = 'UNSET_SELECTED_CHANNEL';
export const SEARCH_CHANNEL_REQUESTED = 'SEARCH_CHANNEL_REQUESTED';
export const SEARCH_CHANNEL_SUCCESS = 'SEARCH_CHANNEL_SUCCESS';
export const SEARCH_CHANNEL_FAILED = 'SEARCH_CHANNEL_FAILED';
export const UPDATE_CHANNEL_PHOTO_FAILED = 'CHANGE_CHANNEL_PHOTO_FAILED';
export const UPDATE_CHANNEL_PHOTO_REQUESTED = 'CHANGE_CHANNEL_PHOTO_REQUESTED';
export const UPDATE_CHANNEL_PHOTO_SUCCESS = 'UPDATE_CHANNEL_PHOTO_SUCCESS';
export const UPDATE_CHANNEL_BACKGROUND_PHOTO_FAILED =
  'CHANGE_CHANNEL_BACKGROUND_PHOTO_FAILED';
export const UPDATE_CHANNEL_BACKGROUND_PHOTO_REQUESTED =
  'CHANGE_CHANNEL_BACKGROUND_PHOTO_REQUESTED';
export const UPDATE_CHANNEL_BACKGROUND_PHOTO_SUCCESS =
  'UPDATE_CHANNEL_BACKGROUND_PHOTO_SUCCESS';
export const UPDATE_CHANNEL_INFO_REQUESTED = 'UPDATE_CHANNEL_INFO_REQUEST';
export const UPDATE_CHANNEL_INFO_SUCCESS = 'UPDATE_CHANNEL_INFO_SUCCESS';
export const UPDATE_CHANNEL_INFO_FAILED = 'UPDATE_CHANNEL_INFO_FAILED';
export const UPDATE_CHANNEL_PERMISSIONS_REQUESTED =
  'UPDATE_CHANNEL_PERMISSIONS_REQUEST';
export const UPDATE_CHANNEL_PERMISSIONS_SUCCESS =
  'UPDATE_CHANNEL_PERMISSIONS_SUCCESS';
export const UPDATE_CHANNEL_PERMISSIONS_FAILED =
  'UPDATE_CHANNEL_PERMISSIONS_FAILED';
export const GET_CHANNEL_USERS_REQUESTED = 'GET_CHANNEL_USERS_REQUESTED';
export const GET_CHANNEL_USERS_SUCCESS = 'GET_CHANNEL_USERS_SUCCESS';
export const GET_CHANNEL_USERS_FAILED = 'GET_CHANNEL_USERS_FAILED';
export const DELETE_CHANNEL_FAILED = 'DELETE_CHANNEL_FAILED';
export const DELETE_CHANNEL_REQUESTED = 'DELETE_CHANNEL_REQUESTED';
export const DELETE_CHANNEL_SUCCESS = 'DELETE_CHANNEL_SUCCESS';
export const UNFOLLOW_CHANNEL_REQUESTED = 'UNFOLLOW_CHANNEL_REQUESTED';
export const UNFOLLOW_CHANNEL_SUCCESS = 'UNFOLLOW_CHANNEL_SUCCESS';
export const UNFOLLOW_CHANNEL_FAILED = 'UNFOLLOW_CHANNEL_FAILED';
export const GET_CHANNEL_ROLES_REQUESTED = 'GET_CHANNEL_ROLES_REQUESTED';
export const GET_CHANNEL_ROLES_SUCCESS = 'GET_CHANNEL_ROLES_SUCCESS';
export const GET_CHANNEL_ROLES_FAILED = 'GET_CHANNEL_ROLES_FAILED';

export const UPDATE_USER_CHANNEL_ROLE_REQUESTED =
  'UPDATE_USER_CHANNEL_ROLE_REQUESTED';
export const UPDATE_USER_CHANNEL_ROLE_SUCCESS =
  'UPDATE_USER_CHANNEL_ROLE_SUCCESS';
export const UPDATE_USER_CHANNEL_ROLE_FAILED =
  'UPDATE_USER_CHANNEL_ROLE_FAILED';
