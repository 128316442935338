export const THIRD_PARTY_URL_REQUESTED = 'THIRD_PARTY_URL_REQUESTED';
export const THIRD_PARTY_URL_FAILED = 'THIRD_PARTY_URL_FAILED';
export const THIRD_PARTY_AUTH_WITH_CODE_REQUESTED =
  'THIRD_PARTY_AUTH_WITH_CODE_REQUESTED';
export const THIRD_PARTY_AUTH_WITH_CODE_SUCCESS =
  'THIRD_PARTY_AUTH_WITH_CODE_SUCCESS';
export const THIRD_PARTY_AUTH_WITH_CODE_FAILED =
  'THIRD_PARTY_AUTH_WITH_CODE_FAILED';
export const LOGIN_REQUESTED = 'LOGIN_REQUESTED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const MUST_SET_INITIAL_USER = 'MUST_SET_INITIAL_USER';
export const USER_RETRIEVED_FAILED = 'USER_RETRIEVED_FAILED';
export const USER_RETRIEVED_SUCCESS = 'USER_RETRIEVED_SUCCESS';
export const VERIFY_USER_EMAIL_REQUESTED = 'VERIFY_USER_EMAIL_REQUESTED';
export const VERIFY_USER_EMAIL_SUCCESS = 'VERIFY_USER_EMAIL_SUCCESS';
export const VERIFY_USER_EMAIL_FAILED = 'VERIFY_USER_EMAIL_FAILED';
export const COMPLETE_PROFILE_REQUESTED = 'COMPLETE_PROFILE_REQUESTED';
export const COMPLETE_PROFILE_SUCCESS = 'COMPLETE_PROFILE_SUCCESS';
export const COMPLETE_PROFILE_FAILED = 'COMPLETE_PROFILE_FAILED';
export const LOGOUT = 'LOGOUT';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const REGISTER_REQUESTED = 'REGISTER_REQUESTED';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILED = 'REGISTER_FAILED';
export const FORGOT_PASSWORD_REQUESTED = 'FORGOT_PASSWORD_REQUESTED';
export const FORGOT_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILED = 'FORGET_PASSWORD_FAILED';
export const RESET_PASSWORD_REQUESTED = 'RESET_PASSWORD_REQUESTED';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';
export const RESEND_MAIL_VERIFICATION_TOKEN_REQUESTED =
  'RESEND_MAIL_VERIFICATION_TOKEN_REQUESTED';
export const RESEND_MAIL_VERIFICATION_TOKEN_SUCCESS =
  'RESEND_MAIL_VERIFICATION_TOKEN_SUCCESS';
export const RESEND_MAIL_VERIFICATION_TOKEN_FAILED =
  'RESEND_MAIL_VERIFICATION_TOKEN_FAILED';
export const INITIALIZE_USER_REQUESTED = 'INITIALIZE_USER_REQUESTED';
export const INITIALIZE_USER_SUCCESS = 'INITIALIZE_USER_SUCCESS';
export const INITIALIZE_USER_FAILED = 'INITIALIZE_USER_FAILED';
export const UPDATE_PROFILE_INFO_REQUESTED = 'UPDATE_PROFILE_INFO_REQUESTED';
export const UPDATE_PROFILE_INFO_SUCCESS = 'UPDATE_PROFILE_INFO_SUCCESS';
export const UPDATE_PROFILE_INFO_FAILED = 'UPDATE_PROFILE_INFO_FAILED';
export const UPDATE_PROFILE_PHOTO_FAILED = 'UPDATE_PROFILE_PHOTO_FAILED';
export const UPDATE_PROFILE_PHOTO_REQUESTED = 'UPDATE_PROFILE_PHOTO_REQUESTED';
export const UPDATE_PROFILE_PHOTO_SUCCESS = 'UPDATE_PROFILE_PHOTO_SUCCESS';
export const UPDATE_PASSWORD_REQUESTED = 'UPDATE_PASSWORD_REQUESTED';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILED = 'UPDATE_PASSWORD_FAILED';
