export const SEARCH_PROFILE_REQUESTED = 'SEARCH_PROFILE_REQUESTED';
export const SEARCH_PROFILE_SUCCESS = 'SEARCH_PROFILE_SUCCESS';
export const SEARCH_PROFILE_FAILED = 'SEARCH_PROFILE_FAILED';
export const LIST_CONTACTS_REQUESTED = 'LIST_CONTACTS_REQUESTED';
export const LIST_CONTACTS_SUCCESS = 'LIST_CONTACTS_SUCCESS';
export const LIST_CONTACTS_FAILED = 'LIST_CONTACTS_FAILED';
export const UPDATE_CONTACT_LAST_ONLINE_DATE =
  'UPDATE_CONTACT_LAST_ONLINE_DATE';
export const SELECT_CONTACT_REQUESTED = 'SELECT_CONTACT_REQUESTED';
export const SELECT_CONTACT_SUCCESS = 'SELECT_CONTACT_SUCCESS';
export const SELECT_CONTACT_FAILED = 'SELECT_CONTACT_FAILED';
export const REMOVE_CONTACT_REQUESTED = 'REMOVE_CONTACT_REQUESTED';
export const REMOVE_CONTACT_SUCCESS = 'REMOVE_CONTACT_SUCCESS';
export const REMOVE_CONTACT_FAILED = 'REMOVE_CONTACT_FAILED';
export const GET_USER_DETAIL_REQUESTED = 'GET_USER_DETAIL_REQUESTED';
export const GET_USER_DETAIL_SUCCESS = 'GET_USER_DETAIL_SUCCESS';
export const GET_USER_DETAIL_FAILED = 'GET_USER_DETAIL_FAILED';
export const LIST_USER_PUBLIC_CHANNELS_REQUESTED =
  'LIST_USER_PUBLIC_CHANNELS_REQUESTED';
export const LIST_USER_PUBLIC_CHANNELS_SUCCESS =
  'LIST_USER_PUBLIC_CHANNELS_SUCCESS';
export const LIST_USER_PUBLIC_CHANNELS_FAILED =
  'LIST_USER_PUBLIC_CHANNELS_FAILED';
export const LIST_USER_PUBLIC_ZONES_REQUESTED =
  'LIST_USER_PUBLIC_ZONES_REQUESTED';
export const LIST_USER_PUBLIC_ZONES_SUCCESS = 'LIST_USER_PUBLIC_ZONES_SUCCESS';
export const LIST_USER_PUBLIC_ZONES_FAILED = 'LIST_USER_PUBLIC_ZONES_FAILED';
