export const ZONE_SUGGESTIONS_REQUESTED = 'ZONE_SUGGESTIONS_REQUESTED';
export const ZONE_SUGGESTIONS_SUCCESS = 'ZONE_SUGGESTIONS_SUCCESS';
export const ZONE_SUGGESTIONS_FAILED = 'ZONE_SUGGESTIONS_FAILED';
export const CHANNEL_SUGGESTIONS_REQUESTED = 'CHANNEL_SUGGESTIONS_REQUESTED';
export const CHANNEL_SUGGESTIONS_SUCCESS = 'CHANNEL_SUGGESTIONS_SUCCESS';
export const CHANNEL_SUGGESTIONS_FAILED = 'CHANNEL_SUGGESTIONS_FAILED';
export const CONTACT_SUGGESTIONS_REQUESTED = 'CONTACT_SUGGESTIONS_REQUESTED';
export const CONTACT_SUGGESTIONS_SUCCESS = 'CONTACT_SUGGESTIONS_SUCCESS';
export const CONTACT_SUGGESTIONS_FAILED = 'CONTACT_SUGGESTIONS_FAILED';
export const NOTIFICATION_REQUESTED = 'NOTIFICATION_REQUESTED';
export const NOTIFICATION_SUCCESS = 'NOTIFICATION_SUCCESS';
export const NOTIFICATION_FAILED = 'NOTIFICATION_FAILED';
export const NOTIFICATION_COUNT_REQUESTED = 'NOTIFICATION_COUNT_REQUESTED';
export const NOTIFICATION_COUNT_SUCCESS = 'NOTIFICATION_COUNT_SUCCESS';
export const NOTIFICATION_COUNT_FAILED = 'NOTIFICATION_COUNT_FAILED';
export const VIEW_NOTIFICATION_REQUESTED = 'VIEW_NOTIFICATION_REQUESTED';
export const VIEW_NOTIFICATION_SUCCESS = 'VIEW_NOTIFICATION_SUCCESS';
export const VIEW_NOTIFICATION_FAILED = 'VIEW_NOTIFICATION_FAILED';
export const READ_NOTIFICATION_REQUESTED = 'READ_NOTIFICATION_REQUESTED';
export const READ_NOTIFICATION_SUCCESS = 'READ_NOTIFICATION_SUCCESS';
export const READ_NOTIFICATION_FAILED = 'READ_NOTIFICATION_FAILED';
