export const CREATE_FOLDER_REQUESTED = 'CREATE_FOLDER_REQUESTED';
export const CREATE_FOLDER_SUCCESS = 'CREATE_FOLDER_SUCCESS';
export const CREATE_FOLDER_FAILED = 'CREATE_FOLDER_FAILED';

export const LIST_FOLDER_REQUESTED = 'LIST_FOLDER_REQUESTED';
export const LIST_FOLDER_SUCCESS = 'LIST_FOLDER_SUCCESS';
export const LIST_FOLDER_FAILED = 'LIST_FOLDER_FAILED';

export const ADD_FOLDER_ITEM_REQUESTED = 'ADD_FOLDER_ITEM_REQUESTED';
export const ADD_FOLDER_ITEM_SUCCESS = 'ADD_FOLDER_ITEM_SUCCESS';
export const ADD_FOLDER_ITEM_FAILED = 'ADD_FOLDER_ITEM_FAILED';

export const REMOVE_FOLDER_ITEM_REQUESTED = 'REMOVE_FOLDER_ITEM_REQUESTED';
export const REMOVE_FOLDER_ITEM_SUCCESS = 'REMOVE_FOLDER_ITEM_SUCCESS';
export const REMOVE_FOLDER_ITEM_FAILED = 'REMOVE_FOLDER_ITEM_FAILED';
