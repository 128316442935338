export const LIST_INVITATION_REQUESTED = 'LIST_INVITATION_REQUESTED';
export const LIST_INVITATION_SUCCESS = 'LIST_INVITATION_SUCCESS';
export const LIST_INVITATION_FAILED = 'LIST_INVITATION_FAILED';
export const GET_INVITATION_RESPONSE_REQUESTED =
  'GET_INVITATION_RESPONSE_REQUESTED';
export const GET_INVITATION_RESPONSE_SUCCESS =
  'GET_INVITATION_RESPONSE_SUCCESS';
export const GET_INVITATION_RESPONSE_FAILED = 'GET_INVITATION_RESPONSE_FAILED';
export const CREATE_CONTACT_INVITATION_REQUESTED =
  'CREATE_CONTACT_INVITATION_REQUESTED';
export const CREATE_CONTACT_INVITATION_SUCCESS =
  'CREATE_CONTACT_INVITATION_SUCCESS';
export const CREATE_CONTACT_INVITATION_FAILED =
  'CREATE_CONTACT_INVITATION_FAILED';
export const CREATE_CHANNEL_INVITATION_REQUESTED =
  'CREATE_CHANNEL_INVITATION_REQUESTED';
export const CREATE_CHANNEL_INVITATION_SUCCESS =
  'CREATE_CHANNEL_INVITATION_SUCCESS';
export const CREATE_CHANNEL_INVITATION_FAILED =
  'CREATE_CHANNEL_INVITATION_FAILED';
export const CREATE_ZONE_INVITATION_REQUESTED =
  'CREATE_ZONE_INVITATION_REQUESTED';
export const CREATE_ZONE_INVITATION_SUCCESS = 'CREATE_ZONE_INVITATION_SUCCESS';
export const CREATE_ZONE_INVITATION_FAILED = 'CREATE_ZONE_INVITATION_FAILED';
