export const GET_USER_ZONES_REQUESTED = 'GET_USER_ZONES_REQUESTED';
export const GET_USER_ZONES_SUCCESS = 'GET_USER_ZONES_SUCCESS';
export const GET_USER_ZONES_FAILED = 'GET_USER_ZONES_FAILED';

export const OPEN_CREATE_ZONE_LAYER = 'OPEN_CREATE_ZONE_LAYER';
export const CLOSE_CREATE_ZONE_LAYER = 'CLOSE_CREATE_ZONE_LAYER';

export const CREATE_ZONE_REQUESTED = 'CREATE_ZONE_REQUESTED';
export const CREATE_ZONE_SUCCESS = 'CREATE_ZONE_SUCCESS';
export const CREATE_ZONE_FAILED = 'CREATE_ZONE_FAILED';

export const JOIN_ZONE_REQUESTED = 'JOIN_ZONE_REQUESTED';
export const JOIN_ZONE_SUCCESS = 'JOIN_ZONE_SUCCESS';
export const JOIN_ZONE_FAILED = 'JOIN_ZONE_FAILED';

export const UPDATE_ZONE_REQUESTED = 'UPDATE_ZONE_REQUESTED';
export const UPDATE_ZONE_SUCCESS = 'UPDATE_ZONE_SUCCESS';
export const UPDATE_ZONE_FAILED = 'UPDATE_ZONE_FAILED';

export const DELETE_USER_ZONE_REQUESTED = 'DELETE_USER_ZONE_REQUESTED';
export const DELETE_USER_ZONE_SUCCESS = 'DELETE_USER_ZONE_SUCCESS';
export const DELETE_USER_ZONE_FAILED = 'DELETE_USER_ZONE_FAILED';

export const DELETE_ZONE_REQUESTED = 'DELETE_ZONE_REQUESTED';
export const DELETE_ZONE_SUCCESS = 'DELETE_ZONE_SUCCESS';
export const DELETE_ZONE_FAILED = 'DELETE_ZONE_FAILED';

export const GET_USER_ZONE_BY_ID_REQUESTED = 'GET_USER_ZONE_BY_ID_REQUESTED';
export const GET_USER_ZONE_BY_ID_SUCCESS = 'GET_USER_ZONE_BY_ID_SUCCESS';
export const GET_USER_ZONE_BY_ID_FAILED = 'GET_USER_ZONE_BY_ID_FAILED';

export const GET_CURRENT_USER_ZONE_REQUESTED =
  'GET_CURRENT_USER_ZONE_REQUESTED';
export const GET_CURRENT_USER_ZONE_SUCCESS = 'GET_CURRENT_USER_ZONE_SUCCESS';
export const GET_CURRENT_USER_ZONE_FAILED = 'GET_CURRENT_USER_ZONE_FAILED';

export const INVITE_TO_ZONE_REQUESTED = 'INVITE_TO_ZONE_REQUESTED';
export const INVITE_TO_ZONE_SUCCESS = 'INVITE_TO_ZONE_SUCCESS';
export const INVITE_TO_ZONE_FAILED = 'INVITE_TO_ZONE_FAILED';

export const SET_CURRENT_USER_ZONE = 'SET_CURRENT_USER_ZONE';

export const SEARCH_ZONE_REQUESTED = 'SEARCH_ZONE_REQUESTED';
export const SEARCH_ZONE_SUCCESS = 'SEARCH_ZONE_SUCCESS';
export const SEARCH_ZONE_FAILED = 'SEARCH_ZONE_FAILED';

export const UPDATE_ZONE_PHOTO_FAILED = 'UPDATE_ZONE_PHOTO_FAILED';
export const UPDATE_ZONE_PHOTO_REQUESTED = 'UPDATE_ZONE_PHOTO_REQUESTED';
export const UPDATE_ZONE_PHOTO_SUCCESS = 'UPDATE_ZONE_PHOTO_SUCCESS';

export const UPDATE_ZONE_INFO_REQUESTED = 'UPDATE_ZONE_INFO_REQUEST';
export const UPDATE_ZONE_INFO_SUCCESS = 'UPDATE_ZONE_INFO_SUCCESS';
export const UPDATE_ZONE_INFO_FAILED = 'UPDATE_ZONE_INFO_FAILED';

export const UPDATE_ZONE_PERMISSIONS_REQUESTED =
  'UPDATE_ZONE_PERMISSIONS_REQUEST';
export const UPDATE_ZONE_PERMISSIONS_SUCCESS =
  'UPDATE_ZONE_PERMISSIONS_SUCCESS';
export const UPDATE_ZONE_PERMISSIONS_FAILED = 'UPDATE_ZONE_PERMISSIONS_FAILED';
export const LEAVE_ZONE_FAILED = 'LEAVE_ZONE_FAILED';
export const LEAVE_ZONE_SUCCESS = 'LEAVE_ZONE_SUCCESS';
export const GET_ZONE_ROLES_REQUESTED = 'GET_ZONE_ROLES_REQUESTED';
export const GET_ZONE_ROLES_SUCCESS = 'GET_ZONE_ROLES_SUCCESS';
export const GET_ZONE_ROLES_FAILED = 'GET_ZONE_ROLES_FAILED';
export const GET_ZONE_USERS_REQUESTED = 'GET_ZONE_USERS_REQUESTED';
export const GET_ZONE_USERS_SUCCESS = 'GET_ZONE_USERS_SUCCESS';
export const GET_ZONE_USERS_FAILED = 'GET_ZONE_USERS_FAILED';
export const UPDATE_USER_ZONE_ROLE_REQUESTED =
  'UPDATE_USER_ZONE_ROLE_REQUESTED';
export const UPDATE_USER_ZONE_ROLE_SUCCESS = 'UPDATE_USER_ZONE_ROLE_SUCCESS';
export const UPDATE_USER_ZONE_ROLE_FAILED = 'UPDATE_USER_ZONE_ROLE_FAILED';
