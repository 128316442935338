export const OPEN_PLAN_A_MEETING_LAYER = 'OPEN_PLAN_A_MEETING_LAYER';
export const CLOSE_PLAN_A_MEETING_LAYER = 'CLOSE_PLAN_A_MEETING_LAYER';

export const OPEN_CREATE_MEETING_LAYER = 'OPEN_CREATE_MEETING_LAYER';
export const CLOSE_CREATE_MEETING_LAYER = 'CLOSE_CREATE_MEETING_LAYER';

export const OPEN_UPDATE_PLAN_A_MEETING_LAYER =
  'OPEN_UPDATE_PLAN_A_MEETING_LAYER';
export const CLOSE_UPDATE_PLAN_A_MEETING_LAYER =
  'CLOSE_UPDATE_PLAN_A_MEETING_LAYER';

export const OPEN_UPDATE_MEETING_LAYER = 'OPEN_UPDATE_MEETING_LAYER';
export const CLOSE_UPDATE_MEETING_LAYER = 'CLOSE_UPDATE_MEETING_LAYER';

export const PLAN_A_MEETING_REQUESTED = 'PLAN_A_MEETING_REQUESTED';
export const PLAN_A_MEETING_SUCCESS = 'PLAN_A_MEETING_SUCCESS';
export const PLAN_A_MEETING_FAILED = 'PLAN_A_MEETING_FAILED';

export const MEETING_CREATE_REQUESTED = 'MEETING_CREATE_REQUESTED';
export const MEETING_CREATE_SUCCESS = 'MEETING_CREATE_SUCCESS';
export const MEETING_CREATE_FAILED = 'MEETING_CREATE_FAILED';

export const GET_USER_MEETING_CONFIG_REQUESTED =
  'GET_USER_MEETING_CONFIG_REQUESTED';
export const GET_USER_MEETING_CONFIG_SUCCESS =
  'GET_USER_MEETING_CONFIG_SUCCESS';
export const GET_USER_MEETING_CONFIG_FAILED = 'GET_USER_MEETING_CONFIG_FAILED';

export const PLAN_A_MEETING_DIALOG_SET = 'PLAN_A_MEETING_DIALOG_SET';
export const PLAN_A_MEETING_DIALOG_FORWARD = 'PLAN_A_MEETING_DIALOG_FORWARD';
export const PLAN_A_MEETING_DIALOG_BACK = 'PLAN_A_MEETING_DIALOG_BACK';

export const SET_INITIAL_MEETING_FORM = 'SET_INITIAL_MEETING_FORM';
export const SET_MEETING_FORM_FIELD = 'SET_MEETING_FORM_FIELD';

export const GET_USER_SUGGESTIONS_FOR_MEETING_REQUESTED =
  'GET_USER_SUGGESTIONS_FOR_MEETING_REQUESTED';
export const GET_USER_SUGGESTIONS_FOR_MEETING_SUCCESS =
  'GET_USER_SUGGESTIONS_FOR_MEETING_SUCCESS';
export const GET_USER_SUGGESTIONS_FOR_MEETING_FAILED =
  'GET_USER_SUGGESTIONS_FOR_MEETING_FAILED';

export const ADD_USER_TO_INVITATION = 'ADD_USER_TO_INVITATION';
export const REMOVE_USER_FROM_INVITATION = 'REMOVE_USER_FROM_INVITATION';
